
<template>
    <div class="test-result">
        <div class="test-result-row">
            <info-panel info-title='总发育商分数'>
                <div class="development-quotient">
                    <div class="score" :class="quotientEvaluation_en">
                        <div class="top">{{quotient}}</div>
                        <div class="bottom">
                            <i class="iconfont" :class="`icon-${quotientEvaluation_en}`"></i>
                            <span>{{quotientEvaluation}}</span>
                        </div>
                    </div>
                </div>
            </info-panel>
            <info-panel info-title='雷达图呈现' class="radio-chart-panel">
                <v-chart :options="radioOptions" class="radio-chart"></v-chart>
            </info-panel>
        </div>
        <info-panel info-title='五大能区'>
            <div class="ergoregion">
                <div class="ergoregion-item" :class="item.title_en"
                    v-for="(item, index) in ergoregion" :key="index">
                    <div class="left">
                        <div class="img-box">
                            <img :src="item.icon" alt="" />
                        </div>
                        <div class="text-box">
                            <p class="title">{{item.title}}</p>
                            <p class="desc" :title="item.desc">{{item.desc}}</p>
                        </div>
                    </div>
                    <div class="right">
                        <p class="evaluation">{{item.evaluation}}</p>
                        <div class="medal-box" v-if="item.evaluation === '很好'">
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_colorful" alt="" />
                        </div>
                        <div class="medal-box" v-else-if="item.evaluation === '不错'">
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                        </div>
                        <div class="medal-box" v-else-if="item.evaluation === '一般'">
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                        </div>
                        <div class="medal-box" v-else-if="item.evaluation === '较弱'">
                            <img :src="ergoregion_medal_colorful" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                        </div>
                        <!-- <div class="medal-box" v-if="item.evaluation === '待提升'">
                            <img :src="ergoregion_medal_gray" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                            <img :src="ergoregion_medal_gray" alt="" />
                        </div> -->
                    </div>
                </div>
            </div>
        </info-panel>
    </div>
</template>

<script>
import Core from '@/core';

import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/radar";
import "echarts/lib/component/tooltip";


export default {
    components: {
        InfoPanel: () => import('@/components/InfoPanel'),
        "v-chart": ECharts,
    },
    props: {
        id: {
            type: [String,Number],
            required: true,
        }
    },
    data() {
        return {
            // 发育商
            quotient: 0,
            quotientEvaluation: '',
            quotientEvaluation_en: '',

            // 雷达图
            radioOptions: {},

            // 能区评价
            ergoregion: [
                {
                    icon: require('@/assets/images/ergoregion/ergoregion-5.png'),
                    title: '大运动',
                    title_en: 'gross-motor',
                    desc: '身体的姿势、头的平衡，以及坐、爬、站、走、跑、跳的能力。',
                    evaluation: ''
                },
                {
                    icon: require('@/assets/images/ergoregion/ergoregion-1.png'),
                    title: '社会行为',
                    title_en: 'social-behaviour',
                    desc: '与周围人们的交往能力和生活自理的能力。',
                    evaluation: ''
                },
                {
                    icon: require('@/assets/images/ergoregion/ergoregion-2.png'),
                    title: '语言',
                    title_en: 'language',
                    desc: '语言理解和语言表达的能力。',
                    evaluation: ''
                },
                {
                    icon: require('@/assets/images/ergoregion/ergoregion-3.png'),
                    title: '适应能力',
                    title_en: 'adaptive-capacity',
                    desc: '对周围自然环境和社会需要作出反应和适应的能力。',
                    evaluation: '一般'
                },
                {
                    icon: require('@/assets/images/ergoregion/ergoregion-4.png'),
                    title: '精细动作',
                    title_en: 'fine-motor',
                    desc: '使用手指的灵活性、以及手与感知的协调能力。',
                    evaluation: ''
                },
            ],
            ergoregion_medal_colorful: require('@/assets/images/ergoregion/medal-colorful.png'),
            ergoregion_medal_gray: require('@/assets/images/ergoregion/medal-gray.png'),
        }
    },
    methods: {
        getEvaluationResult() { // 测评结果
            Core.operationApi.Record.evaluationResult(this.id).then((res) => {
                let data = JSON.parse(res.detail.data)
                if (data.monthAge < 1) {data.monthAge = 1;}
                let score = [];
                let sum = 0;

                for (let i = 1; i <= 5; i++) {
                    sum += data.scoreList['type'+i].score
                    score.push(data.scoreList['type'+i].score / 10)
                    // 能区评价
                    switch (data.scoreList['type'+i].commentType) {
                        case 1:
                            this.ergoregion[i-1].evaluation = '较弱'
                            break;
                        case 2:
                            this.ergoregion[i-1].evaluation = '一般'
                            break;
                        case 3:
                            this.ergoregion[i-1].evaluation = '不错'
                            break;
                        case 4:
                            this.ergoregion[i-1].evaluation = '很好'
                            break;
                    }
                }

                // 发育商
                let mentalAge = (sum / 10) / 5   // 智龄
                this.quotient = Math.round(mentalAge / (data.monthAge) * 100)
                // console.log("getEvaluationResult -> mentalAge", mentalAge)
                // console.log("getEvaluationResult -> data.monthAge", data.monthAge)
                // console.log("getEvaluationResult -> quotient", this.quotient)
                if (this.quotient >= 130) {
                        this.quotientEvaluation = '优秀';
                        this.quotientEvaluation_en = 'excellent';
                    } else if (this.quotient >= 110 && this.quotient < 130) {
                        this.quotientEvaluation = '良好';
                        this.quotientEvaluation_en = 'well';
                    } else if (this.quotient >= 80 && this.quotient < 110) {
                        this.quotientEvaluation = '中等';
                        this.quotientEvaluation_en = 'medium';
                    } else if (this.quotient >= 70 && this.quotient < 80) {
                        this.quotientEvaluation = '临界偏低';
                        this.quotientEvaluation_en = 'on-the-low-side';
                    } else {
                        this.quotientEvaluation = '智力发育障碍';
                        this.quotientEvaluation_en = 'developmental-disorder';
                }

                // 雷达图
                let max = Math.ceil( Math.max(...score) / 10) * 10
                console.log("getEvaluationResult -> max", max)
                // if (max > 84) {max = 84;}
                this.radioOptions = {
                    title: {},
                    tooltip: {},
                    legend: {
                        data: ['测试结果']
                    },
                    radar: {
                        name: {
                            formatter:'{value}',
                            textStyle: {
                                color: '#5986E8',
                                fontSize: 14,
                                lineHeight: 20,
                            }
                        },
                        indicator: [
                            { name: '大运动', max},
                            { name: '社会行为', max},
                            { name: '语言', max},
                            { name: '适应能力', max},
                            { name: '精细动作', max},
                        ],
                        center: ['50%', '50%'],
                        radius: 93,
                        startAngle: -127,
                        splitNumber: 4,
                        shape: 'polygon',
                        splitArea: {
                            areaStyle: {
                                color: '#EEF2FC'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(89, 134, 232, 0.54)'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(89, 134, 232, 0.54)',
                                type: 'dashed'
                            }
                        },
                    },
                    series: [{
                        name: '测试结果',
                        type: 'radar',
                        itemStyle: {color: '#5986E8'},
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#5986E8' // 0% 处的颜色
                                }, {
                                    offset: 1, color: 'rgba(89, 134, 232, 0)' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        data: [
                            {
                                value: score,
                                name: '测试结果',
                                tooltip: {
                                    show: true,
                                    trigger: 'item',
                                }
                            }
                        ]
                    }]
                }
            });
        }
    },
    created() {
        this.getEvaluationResult();
    }
}
</script>


<style lang='scss'>
    .test-result {
        .development-quotient {
            height: 255px;
            @include flex(row, center ,center);
            .score{
                width: 290px;
                height: 152px;
                padding: 10px 0;
                text-align: center;
                border-radius:10px;
                &.excellent {
                    background-color: #FDEEF0;
                    color: #EC5D6E;
                }
                &.well {
                    background-color: #E3F5EC;
                    color: #3FCE85;
                }
                &.medium {
                    background-color: #EDEEF9;
                    color: #7D89FA;
                }
                &.on-the-low-side {
                    background-color: #FAF0E9;
                    color: #FF9F59;
                }
                &.developmental-disorder {
                    background-color: #FAC8DB;
                    color: #F83C83;
                }
                .top {
                    font-size: 70px;
                    line-height: 98px;
                    margin-bottom: 4px;
                }
                .bottom {
                    font-size: 36px;
                    line-height: 50px;
                    i.iconfont {
                        font-size: 36px;
                        margin-right: 7px;
                    }
                }
            }
        }
        .radio-chart {
            height: 255px;
            width: 100%;
            min-width: 550px;
            @include flex(row, center ,center);
            box-sizing: border-box;
            div {
                @include flex(row, center ,center);
            }
        }
        .ergoregion {
            padding: 30px 30px 0;
            @include flex(row, flex-start, center);
            flex-wrap: wrap;
            .ergoregion-item {
                @include flex(row, space-between, center);
                width: calc((100% - 40px) / 3);
                padding: 16px 14px;
                box-sizing: border-box;
                border-radius:10px;
                &.language    {background:#FEFAF2;}
                &.fine-motor  {background:#EEF1FF;}
                &.gross-motor {background:#F5FAF5;}
                &.adaptive-capacity {background:#FEF6F4;}
                &.social-behaviour  {background:#F2F8FC;}
                &:nth-child(3n-1) {
                    margin-left: 20px;
                    margin-right: 20px;
                }
                margin-bottom: 30px;
                .left {
                    display: flex;
                    align-items: center;
                    width: calc(100% - 80px);
                    .img-box {
                        width: 40px;
                        height: 40px;
                        padding-right: 10px;
                        img {
                            width: 100%;
                        }
                    }
                    .text-box {
                        width: calc(100% - 55px);
                        .title {
                            font-size:16px;
                            font-weight:500;
                            color:rgba(79,79,90,1);
                            line-height:22px;
                            margin-bottom: 6px;
                        }
                        .desc {
                            font-size:14px;
                            color:rgba(102,110,135,1);
                            line-height:19px;
                            height: 38px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .right {
                    width: 80px;
                    .evaluation {
                        text-align: center;
                        margin-bottom: 6px;
                        font-size:14px;
                        font-weight:500;
                        color:rgba(63,69,73,1);
                        line-height:20px;
                    }
                    .medal-box {
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            @media (max-width: 1100px) { 
                .ergoregion-item {
                    width: calc((100% - 20px) / 2);
                    &:nth-child(3n-1) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                    &:nth-child(2n) {
                        margin-left: 20px;
                    }
                }
            }
            @media (max-width: 850px) { 
                .ergoregion-item {
                    width: 100%;
                    &:nth-child(2n) {
                        margin-left: 0;
                    }
                    margin: 0 0 30px;
                }
            }
        }
    }
</style>

<style lang="scss">
    .test-result-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .info-panel {
            width: calc(50% - 7px);
        }
        @media (max-width: 992px) { 
            .info-panel {
                width: 100%;
            }
        }
    }
    .radio-chart-panel {
        .info-panel-body {
            display: flex;
            justify-content: center;
        }
    }
</style>